<template>
  <v-app>
    <div id="list" class="list">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="50"
        :item-class="rowClass"
        hide-default-footer
        class="stock-exchanges exchanges"
      >
        <template v-slot:item.name="{ item }">
          <div class="name">
            <h2>{{ item.name }}</h2>
          </div>
        </template>
        <template v-slot:item.time="">
          <div class="timecell">
            <v-chip color="primary" dark>
              <v-icon dark>
                mdi-clock-time-five-outline
              </v-icon>
              &nbsp;&nbsp;Open 24/7
            </v-chip>
          </div>
        </template>
        <template v-slot:item.tools="">
          <div class="tools">
            <v-btn
              color="#f3ba2f"
              href="https://www.binance.com/en/register?ref=SZJZBZU0"
              target="_blank"
              rel="noopener"
              name="Binance"
              small
            >
              <v-icon dark>
                mdi-bitcoin
              </v-icon>
              Binance
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-app>
</template>

<script>
import exchanges from "../data/exchanges-cryptocurrency.json";

export default {
  name: "ListComponent",
  data: function() {
    return {
      search: "",
      headers: [
        {
          text: "#",
          value: "rank"
        },
        {
          text: "Exchange",
          value: "name"
        },
        {
          text: "Volume",
          value: "volume"
        },
        {
          text: "Now",
          align: "center",
          value: "time"
        },
        {
          text: "Go trade",
          align: "center",
          value: "tools"
        }
      ],
      list: Object.values(exchanges)
    };
  },
  created() {},
  methods: {
    rowClass: function() {
      return "";
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/scss/list.scss";
</style>
