<template>
  <div class="cryptocurrency text-left">
    <h1>Cryptocurrency trading hours</h1>
    <h3>
      Cryptocurrency exchanges are opened 24/7.
    </h3>
    <br />
    <CryptoList />
  </div>
</template>

<script>
// @ is an alias to /src
import CryptoList from "@/components/CryptoList.vue";

export default {
  name: "Cryptocurrency",
  components: {
    CryptoList
  }
};
</script>
